import React from "react"
import SbEditable from "storyblok-react"

const Heading = props => {
  return (
    <SbEditable content={props.blok}>
      <h2>{props.blok.content}</h2>
    </SbEditable>
  )
}

export default Heading
