import React from "react"
import { Heading } from "@chakra-ui/core"
import ReactMardown from "react-markdown"
import SbEditable from "storyblok-react"

const AdvancedText = props => {
  return (
    <SbEditable content={props.blok}>
      <div>
        <Heading
          as={`h${props.blok.title_size}`}
          size={props.blok.title_visual_size}
          style={{ textAlign: props.blok.title_align }}
          className={
            props.blok.title_color === "dark" ? "--u-dark" : "--u-light"
          }
        >
          {props.blok.title}
        </Heading>
        <ReactMardown
          source={props.blok.content}
          className={
            props.blok.text_color === "dark" ? "--u-dark" : "--u-light"
          }
        />
      </div>
    </SbEditable>
  )
}

export default AdvancedText
