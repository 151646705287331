import React from "react"
import SbEditable from "storyblok-react"

const Link = props => {
  return (
    <SbEditable content={props.blok}>
      <div>
        <a href={props.blok.link.cached_url} target="_blank" rel="noopener noreferrer">
          {props.blok.title}
        </a>
      </div>
    </SbEditable>
  )
}

export default Link
