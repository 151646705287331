export function useIsInEditorHook() {
  let isIn = false

  if (typeof window !== `undefined`) {
    const fullUrl = window.location.href
    isIn = fullUrl.search("_storyblok") > -1
  }

  return isIn
}
