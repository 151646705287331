import React from "react"
import SbEditable from "storyblok-react"

import Components from "../components.js"
import withSpacing from "../utils/withSpacingHOC"

const Container = props => {
  return (
    <SbEditable content={props.blok}>
      <div
        id={props.blok.id && props.blok.id}
        style={{
          backgroundColor: props.blok.background && props.blok.background.color,
          overflow: "hidden",
        }}
        className={`container ${props.blok.fluid &&
          "is-fluid"} ${props.spacing && props.spacing}`}
      >
        {props.blok.content.map(blok =>
          React.createElement(Components(blok.component), {
            key: blok._uid,
            blok: blok,
          })
        )}
      </div>
    </SbEditable>
  )
}

export default withSpacing(Container)
