import React from "react"
import { getSpacingClass } from "../../helpers/styling"
import { classNames } from "../../helpers/general"

/* 
    Documentation:
        To use withSpacing HOC
        1) wrap component you like to have box model spacing, 
        2) add spacing-plugin schema to component
        3) add props.spacing variable to component className prop
        example for Row component:
        1. export default withSpacing(Row)
        2. 
            box_model: {
                type: 'custom',
                field_type: 'spacing-plugin',
                options: []
            },
        3. <div className={`ef-row ${alignmentClass} ${props.spacing && props.spacing}`}>
*/
const withSpacing = WrappedComponent => {
  return props => {
    const box_model = props.blok.box_model

    // getting spacing for s / m / l breakpoints
    const spacing_s =
      box_model && box_model.s
        ? Object.entries(box_model.s)
            .filter(item => item[0] !== "_uid") // we don't need _uid of box_model field to determine what classes to apply
            .map(item =>
              item[1] !== "-" ? getSpacingClass(item[1], "s", item[0]) : false
            )
        : [false]
    const spacing_m =
      box_model && box_model.m
        ? Object.entries(box_model.m)
            .filter(item => item[0] !== "_uid")
            .map(item =>
              item[1] !== "-" ? getSpacingClass(item[1], "m", item[0]) : false
            )
        : [false]
    const spacing_l =
      box_model && box_model.l
        ? Object.entries(box_model.l)
            .filter(item => item[0] !== "_uid")
            .map(item =>
              item[1] !== "-" ? getSpacingClass(item[1], "l", item[0]) : false
            )
        : [false]

    const classesToApply = [...spacing_s, ...spacing_m, ...spacing_l]
    const spacing = classNames(...classesToApply)
    return <WrappedComponent {...props} spacing={spacing} />
  }
}

export default withSpacing
