import React from "react"
import SbEditable from "storyblok-react"

import Components from "../components.js"
import withSpacing from "../utils/withSpacingHOC"

const Column = props => {
  return (
    <SbEditable content={props.blok}>
      <div className={props.spacing && props.spacing}>
        {props.blok.components &&
          props.blok.components.map(blok =>
            React.createElement(Components(blok.component), {
              key: blok._uid,
              blok: blok,
            })
          )}
      </div>
    </SbEditable>
  )
}

export default withSpacing(Column)
