import React from "react"
import CalendarHeatmap from "react-calendar-heatmap"
import "react-calendar-heatmap/dist/styles.css"

const runningHeatmapStartDate = new Date("2019-12-01")
const runningHeatmapEndDate = new Date("2020-12-01")

const runningHeatmapData = [{ date: "2019-12-03", count: 1 }]

const Heatmap = props => {
  return (
    <div className="container">
      <CalendarHeatmap
        startDate={runningHeatmapStartDate}
        endDate={runningHeatmapEndDate}
        values={runningHeatmapData}
        onMouseOver={(event, value) => null}
        classForValue={value => {
          if (!value) {
            return "color-empty"
          }
          return `color-gitlab-${value.count}`
        }}
      />
    </div>
  )
}

export default Heatmap
