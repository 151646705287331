import React from "react"
import styled from "styled-components"

export const StoryblokTooltip = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  padding: 2px 5px;
  background-color: #59c5c6;
  font-size: 9px;
  line-height: 9px;
  text-transform: uppercase;
  font-family: Verdana;
  font-weight: 700;
  color: #fff;
`

export const FloatingControls = styled.div`
  display: none;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  position: absolute;
  left: 390px;
  top: -64px;
  width: 140px;
  height: 64px;
  padding-bottom: 2px;
  border: 1px solid #59c5c6;
  background-color: #fcfcfc;
`

export const ControlsWrapper = styled.div`
  width: 100%;
  position: relative;

  :hover ${FloatingControls} {
    display: flex;
  }
`
