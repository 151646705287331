import Page from "./Page"
import ComponentNotFound from "./component_not_found"
import Text from "./Text/Text"
import Card from "./Card/Card"
import Column from "./Column/Column"
import Container from "./Container/Container"
import FutureContainer from "./FutureContainer/FutureContainer"
import Row from "./Row/Row"
import Heatmap from "./Heatmap/Heatmap"
import Link from "./Link/Link"
import Heading from "./Heading/Heading"
import AdvancedText from "./AdvancedText/AdvancedText"

import { injectScopedInternalComponentList } from "@storyblok-components/utils"
import * as ScopedSimpleTextBlock from "@storyblok-components/simple-text-block"

const withComponentList = type => componentList => {
  if (typeof componentList[type] === "undefined") {
    return ComponentNotFound
  }
  return componentList[type]
}

const Components = type => withComponentList(type)(ComponentList)

const LocalComponentList = {
  page: Page,
  text: Text,
  card: Card,
  column: Column,
  container: Container,
  "future-container": FutureContainer,
  row: Row,
  heatmap: Heatmap,
  link: Link,
  heading: Heading,
  "advanced-text": AdvancedText,
}

const ScopedComponentList = {
  // The injectScopedInternalComponentList is method to inject whole components.js file if scope
  // component have more internal components than just one
  ...injectScopedInternalComponentList(
    {
      components: [ScopedSimpleTextBlock.ComponentList],
    },
    Components // injecting all Components you will use normally, into any of scoped components
  ),
  // If you are using scoped component, but inside your local project (installed with sb-mig add component-name --copy)
  // then use this syntax to import and use component and to inject component list to it
  // surface: withComponents(Surface)(Components)
  // "web-ui-surface": withComponents(SurfaceStuff.Surface)(Components)
}

const ComponentList = {
  ...LocalComponentList,
  ...ScopedComponentList,
}

export default Components
