import React from "react"
import SbEditable from "storyblok-react"

const Card = props => {
  let backgroundColor = "#fff"
  let color = "#000"
  switch (props.blok.priority) {
    case "0":
      backgroundColor = "#fff"
      color = "#000"
      break

    case "1":
      backgroundColor = "green"
      color = "#fff"
      break

    case "2":
      backgroundColor = "purple"
      color = "#fff"
      break

    case "3":
      backgroundColor = "yellow"
      color = "#000"
      break

    case "4":
      backgroundColor = "#efefef"
      color = "#000"
      break

    default:
      backgroundColor = "#fff"
      color = "#000"
      break
  }

  return (
    <SbEditable content={props.blok}>
      <div
        style={{
          border: "1px solid black",
          padding: "20px",
          margin: "10px",
          backgroundColor,
          color,
        }}
      >
        {props.blok.content}
      </div>
    </SbEditable>
  )
}

export default Card
