import React from "react"
import SbEditable from "storyblok-react"

import Components from "../components.js"
import withSpacing from "../utils/withSpacingHOC"
import withUltimateEditor from "../UltimateEditor/withUltimateEditor"

const FutureContainer = props => {
  return (
    <SbEditable content={props.blok}>
      <div
        id={props.blok.id && props.blok.id}
        style={{
          backgroundColor: props.blok.background && props.blok.background.color,
        }}
        className={`container ${props.blok.fluid &&
          "is-fluid"} ${props.spacing && props.spacing}`}
      >
        <>
          {/* <h1>{props.blok.licznik}</h1> */}
          <h1>{props.blok.id}</h1>
          {props.blok.content.map(blok =>
            React.createElement(Components(blok.component), {
              key: blok._uid,
              blok: blok,
            })
          )}
        </>
      </div>
    </SbEditable>
  )
}

export default withUltimateEditor(withSpacing(FutureContainer))({
  name: "some option",
  arrayOfOptions: [1, 2, 3],
})
