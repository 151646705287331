export const classNames = (...items) => items.filter(a => a).join(" ")

export const findById = (o, _uid) => {
  // Early return
  if (o._uid === _uid) {
    return o
  }
  var result, p
  for (p in o) {
    if (o.hasOwnProperty(p) && typeof o[p] === "object") {
      result = findById(o[p], _uid)
      if (result) {
        return result
      }
    }
  }
  return result
}

export const findByIdAndReplace = (o, _uid, toReplace) => {
  //Early return
  if (o._uid === _uid) {
    o = toReplace
    return o
  }
  var result, p
  for (p in o) {
    if (o.hasOwnProperty(p) && typeof o[p] === "object") {
      result = findById(o[p], _uid)
      if (result) {
        return result
      }
    }
  }
  return result
}
