import React, { useState, useRef } from "react"

import { useIsInEditorHook } from "../../helpers/hooks"
import { findByIdAndReplace } from "../../helpers/general"
import { Maximize, Battery } from "./Icons"
import { StoryblokTooltip, FloatingControls, ControlsWrapper } from "./styled"

/* 
    Documentation:
        Ultimate Editor is and will be extension of Storyblok CMS, which will give you possibility to 
        edit, add, and manage some stuff, directly from visual.
        So it is extending a little awesome feature of live view, to actually INTERACT with live view.

        There will be no specific instruction to use this thing yet, cause it's very single purpose for now.
        The fastest way to get this and use somewhere, is to clone this and modify for you use, by creating another
        HOC kinda inheriting from this one; with your own functionality.

        Important notice, you need to duplicate some variables from your .env file. You need your storyblok auth token,
        to be able to call storyblok managment api, and your space id; both in a GATSBY env format:

        GATSBY_STORYBLOK_SPACE_ID=123456
        GATSBY_STORYBLOK_OAUTH_TOKEN=ijnslvhbrpw83jq4infpu-n34qgp

        Biggest bottlenecks:
        - we can only update the whole story, so if we edit component which is deeep inside the story object, maybe it will take to
          long time, to find it and replace
        - there it tempHotReloadingData state, which is used to hot reload stuff, to show changes visually, but to see changes applied to
          right column, tou need to REFRESH whole storyblok space, you dont need to hit save - it will save LIVE
*/
const withUltimateEditor = WrappedComponent => options => {
  return props => {
    const [isUltimateEditorDirty, setIsUltimateEditorDirty] = useState(false)
    const [tempHotReloadData, setTempHotReloadData] = useState({})
    const [magicSetter, setMagicSetter] = useState({})
    const tempBlokData = useRef({})
    const isInEditor = useIsInEditorHook()

    const onIconClick = async (event, payload) => {
      // shallow copy
      tempBlokData.current = isUltimateEditorDirty
        ? tempHotReloadData
        : props.blok

      // entries from payload, to loop through and modify stuff
      const payloadEntriesToChange = Object.entries(payload)

      // mapping through object passed to function, and changing schema for clicked component
      payloadEntriesToChange.forEach(singlePayload => {
        tempBlokData.current[singlePayload[0]] = singlePayload[1]
      })

      // when ultimate editor is dirty, we show temp hot reload to world
      setIsUltimateEditorDirty(true)
      setTempHotReloadData(tempBlokData.current)

      // searching for ._uid in big story object, and replacing data with new one
      // this function is mutating props.story.content object, which is definitelly not ideal
      // but the structure could be very deep, and cloning it would be also not the best idea
      // although, definitelly room to improve a lot... now it's very not battle tested, very beta
      findByIdAndReplace(
        props.story.content,
        props.blok._uid,
        tempBlokData.current
      )

      const dataToSend = {
        story: props.story,
      }

      // making update request
      await fetch(
        `https://mapi.storyblok.com/v1/spaces/${process.env.GATSBY_STORYBLOK_SPACE_ID}/stories/${props.story.id}/`,
        {
          method: "PUT",
          mode: "cors",
          cache: "no-cache",
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json",
            Authorization: process.env.GATSBY_STORYBLOK_OAUTH_TOKEN,
          },
          body: JSON.stringify(dataToSend),
        }
      )
        .then(res => res.json())
        .catch(err => {
          console.log(err)
          return false
        })
      console.log(
        "Now you should reload browser to see changes in right panel, you see it in left visuals instantly!"
      )
      setMagicSetter(value => value + 1)
    }

    if (isInEditor) {
      return (
        <ControlsWrapper>
          <FloatingControls>
            <StoryblokTooltip>controls</StoryblokTooltip>
            <Maximize onClick={event => onIconClick(event, { fluid: true })} />
            <Battery
              onClick={event => onIconClick(event, { id: "something funny" })}
            />
          </FloatingControls>
          <WrappedComponent
            {...props}
            blok={isUltimateEditorDirty ? tempHotReloadData : props.blok}
            optionsPassed={options}
          />
        </ControlsWrapper>
      )
    } else {
      return <WrappedComponent {...props} />
    }
  }
}

export default withUltimateEditor
